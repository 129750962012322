import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React from 'react'
import { Alert, Badge, Button, Dropdown, Form } from 'react-bootstrap'
import { useGetCountriesQuery } from '../../../components/api'
// import { useGetCompaniesQuery } from '../../../components/api/AccountsApix'
import Layout from '../../../components/Layout'
import MUIDataTable from '../../../components/MuiDatatableUtils'
import { unauthNavTo } from '../../../components/navigation'
import Spinner from '../../../components/spinner'
import { getLabelFromArray } from '../../../components/static'
import VendorDeleteParticipantModal from '../../../pages/app/kitchensink/vendorDeleteParticipant'
import {
  useGetCompaniesQuery,
  useGetGroupsQuery,
  useGetuserDetailsQuery
} from '../../../utils/api'
import ParticipantsInviteCompanyModal from '../kitchensink/participantsInviteCompanyModal'
const Statuses = [
  'All',
  'Active',
  'Applied',
  'Declined',
  'Invited',
  'Suspended'
]
const Page = () => {
  // ANCHOR state
  const [selectedRowIndexes, setSelectedRowIndexes] = React.useState([])
  const [openDeleteParticipantModal, setOpenDeleteParticipantModal] =
    React.useState(false)
  const [selectedParticipants, setSelectedParticipants] = React.useState([])
  const [showInviteCompanyModal, setShowInviteCompanyModal] =
    React.useState(false)
  const [tabChanged, setTabChanged] = React.useState(false)
  const [tabSelectedIndex, setTabSelectedIndex] = React.useState(0)
  const [filteredData, setFilteredData] = React.useState([])
  const [storeAllData, setStoreAllData] = React.useState([])

  const [statusSelected, setStatusSelected] = React.useState('all')
  const [countrySelected, setCountrySelected] = React.useState('All')
  const [groupsSelected, setGroupsSelected] = React.useState('All')
  const deselectAll = () => {
    setSelectedRowIndexes([])
  }

  // ANCHOR query
  const companiesQuery = useGetCompaniesQuery()
  const groupsQuery = useGetGroupsQuery()
  const countriesQuery = useGetCountriesQuery()
  const { data: { _isVendor = false } = {} } = useGetuserDetailsQuery()

  React.useEffect(() => {})
  const goPage = (x) => {
    unauthNavTo.viewParticipantPage(x)
  }

  // ANCHOR functions

  const HtmlTooltip = withStyles((theme) => ({
    arrow: {
      color: '#F5F8FF',
      background: '#F5F8FF',
      borderRadius: '10px'
    },
    tooltip: {

      maxWidth: 220,
      paddingTop: '8px',
      paddingBottom: '8px',
      alignItems: 'center',
      boxShadow: '0px 1px 2px rgba(58, 58, 68, 0.24), 0px 2px 4px rgba(90, 91, 106, 0.24)',
      background: '#F5F8FF',
      borderRadius: '10px',
      gap: '8px'
    }
  }))(Tooltip)

  const filterBaseOnTab = (e) => {
    const companyList = storeAllData
    const cleanedData = companyList.filter((c) => c.nickName !== null).sort((a, b) =>
      a.nickName.localeCompare(b.nickName, undefined, {
        sensitivity: 'base'
      })
    )
    switch (e.target.innerText) {
      case 'All':
        setFilteredData(cleanedData)
        setTabSelectedIndex(0)
        break
      case 'Active':
        setFilteredData(companyList.filter((c) => c.status === 'active'))
        setTabSelectedIndex(1)
        break
      case 'Suspended/Declined':
        setFilteredData(companyList.filter((c) => c.status === 'suspended' || c.status === 'declined'))
        setTabSelectedIndex(3)
        break
      case 'Invited/Applied':
        setFilteredData(companyList.filter((c) => c.status === 'invited' || c.status === 'applied'))
        setTabSelectedIndex(2)
        break
      default:
        break
    }
    setTabChanged(true)
  }
  const handleStatusChange = (status) => {
    const companyList = storeAllData
    const cleanedData = companyList.filter((c) => c.nickName !== null).sort((a, b) =>
      a.nickName.localeCompare(b.nickName, undefined, {
        sensitivity: 'base'
      })
    )
    let tempFilter
    if (statusSelected.includes(status.toLowerCase())) {
      if (status.toLowerCase() === 'all') {
        let temp = cleanedData
        if (groupsSelected !== 'All') {
          temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
        }
        if (countrySelected !== 'All') {
          temp = temp.filter((c) => countrySelected.includes(c.country))
        }
        setFilteredData(temp)

        setStatusSelected([])
        return
      }
      tempFilter = statusSelected.filter((s) => s !== status.toLowerCase())
    } else {
      if (status.toLowerCase() === 'all') {
        let temp = cleanedData
        if (groupsSelected !== 'All') {
          temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
        }
        if (countrySelected !== 'All') {
          temp = temp.filter((c) => countrySelected.includes(c.country))
        }
        setFilteredData(temp)

        setStatusSelected('all')
        return
      }
      tempFilter = typeof statusSelected === 'string' ? [statusSelected, status.toLowerCase()] : [...statusSelected, status.toLowerCase()]
    }
    tempFilter = tempFilter.filter((s) => s !== 'all')
    setStatusSelected(tempFilter)

    if (tempFilter.length === 0) {
      let temp = cleanedData
      if (groupsSelected !== 'All') {
        temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
      }
      if (countrySelected !== 'All') {
        temp = temp.filter((c) => countrySelected.includes(c.country))
      }
      setFilteredData(temp)

      return
    }
    let temp = companyList.filter((c) => tempFilter.includes(c.status))
    if (groupsSelected !== 'All') {
      temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
    }
    if (countrySelected !== 'All') {
      temp = temp.filter((c) => countrySelected.includes(c.country))
    }
    setFilteredData(temp)
  }
  const handleCountryChange = (country) => {
    const companyList = storeAllData
    const cleanedData = companyList.filter((c) => c.nickName !== null).sort((a, b) =>
      a.nickName.localeCompare(b.nickName, undefined, {
        sensitivity: 'base'
      })
    )
    let tempFilter
    if (countrySelected.includes(country)) {
      if (country === 'All') {
        let temp = cleanedData
        if (statusSelected !== 'all') {
          temp = temp.filter((c) => statusSelected.includes(c.status))
        }
        if (groupsSelected !== 'All') {
          temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
        }
        setFilteredData(temp)
        setCountrySelected([])
        return
      }
      tempFilter = countrySelected.filter((s) => s !== country)
    } else {
      if (country === 'All') {
        let temp = cleanedData
        if (statusSelected !== 'all') {
          temp = temp.filter((c) => statusSelected.includes(c.status))
        }
        if (groupsSelected !== 'All') {
          temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
        }
        setFilteredData(temp)
        setCountrySelected('All')
        return
      }
      tempFilter = typeof countrySelected === 'string' ? [countrySelected, country] : [...countrySelected, country]
    }
    tempFilter = tempFilter.filter((s) => s !== 'All')
    setCountrySelected(tempFilter)

    if (tempFilter.length === 0) {
      let temp = cleanedData
      if (statusSelected !== 'all') {
        temp = temp.filter((c) => statusSelected.includes(c.status))
      }
      if (groupsSelected !== 'All') {
        temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
      }
      setFilteredData(temp)
      return
    }
    let temp = companyList.filter((c) => tempFilter.includes(c.country))
    if (statusSelected !== 'all') {
      temp = temp.filter((c) => statusSelected.includes(c.status))
    }
    if (groupsSelected !== 'All') {
      temp = temp.filter((c) => c.groups.some((g) => groupsSelected.includes(g)))
    }
    setFilteredData(temp)
  }
  const handleGroupsChange = (group) => {
    const companyList = storeAllData
    const cleanedData = companyList.filter((c) => c.nickName !== null).sort((a, b) =>
      a.nickName.localeCompare(b.nickName, undefined, {
        sensitivity: 'base'
      })
    )
    let tempFilter
    if (groupsSelected.includes(group)) {
      if (group === 'All') {
        let temp = cleanedData
        if (statusSelected !== 'all') {
          temp = temp.filter((c) => statusSelected.includes(c.status))
        }
        if (countrySelected !== 'All') {
          temp = temp.filter((c) => countrySelected.includes(c.country))
        }
        setFilteredData(temp)
        setGroupsSelected([])
        return
      }
      tempFilter = groupsSelected.filter((s) => s !== group)
    } else {
      if (group === 'All') {
        let temp = cleanedData
        if (statusSelected !== 'all') {
          temp = temp.filter((c) => statusSelected.includes(c.status))
        }
        if (countrySelected !== 'All') {
          temp = temp.filter((c) => countrySelected.includes(c.country))
        }
        setFilteredData(temp)
        setGroupsSelected('All')
        return
      }
      tempFilter = typeof groupsSelected === 'string' ? [groupsSelected, group] : [...groupsSelected, group]
    }
    tempFilter = tempFilter.filter((s) => s !== 'All')
    setGroupsSelected(tempFilter)

    if (tempFilter.length === 0) {
      let temp = cleanedData
      if (statusSelected !== 'all') {
        temp = temp.filter((c) => statusSelected.includes(c.status))
      }
      if (countrySelected !== 'All') {
        temp = temp.filter((c) => countrySelected.includes(c.country))
      }
      setFilteredData(temp)
      return
    }
    let temp = companyList.filter((c) => c.groups.some((g) => tempFilter.includes(g)))
    if (statusSelected !== 'all') {
      temp = temp.filter((c) => statusSelected.includes(c.status))
    }
    if (countrySelected !== 'All') {
      temp = temp.filter((c) => countrySelected.includes(c.country))
    }
    setFilteredData(temp)
  }
  const getTagWidth = (tag) => {
    const dummyTag = document.createElement('div')
    dummyTag.style.visibility = 'hidden'
    dummyTag.style.whiteSpace = 'nowrap'
    dummyTag.innerText = tag
    document.body.appendChild(dummyTag)
    const width = dummyTag.offsetWidth
    document.body.removeChild(dummyTag)
    return width
  }

  React.useEffect(() => {
    if (companiesQuery.data && groupsQuery.data) {
      const companies = companiesQuery.data
      const groups = groupsQuery.data

      const results = {}

      companies.forEach(company => {
        results[company.id] = []
      })

      groups.forEach(group => {
        group.groupMembers.forEach(member => {
          results[member.id].push(group.name)
        })
      })
      companies.forEach(company => {
        company.groups = results[company.id]
      })

      const cleanedData = companies.filter((c) => c.nickName !== null).sort((a, b) =>
        a.nickName.localeCompare(b.nickName, undefined, {
          sensitivity: 'base'
        })
      )
      setFilteredData(cleanedData)
      setStoreAllData(cleanedData)
    }
  }, [companiesQuery.data, groupsQuery.data])

  // ANCHOR React <>
  return (
    <>
      <Layout>
        <Layout.Title>
          <h5>Participants</h5>
          <Button
            variant="primary"
            className="d-flex align-items-center"
            onClick={() => setShowInviteCompanyModal(true)}
          >
            Invite Company
          </Button>
        </Layout.Title>
        <Layout.CardGrey>
{/*          <SimpleTabs
            elements={[
              { label: 'All', onClick: filterBaseOnTab },
              { label: 'Active', onClick: filterBaseOnTab },
              { label: 'Invited/Applied', onClick: filterBaseOnTab },
              { label: 'Suspended/Declined', onClick: filterBaseOnTab }
            ]}
            selectedIndex={tabSelectedIndex}
            size="md"
          /> */}
          <div className="card-body rounded-soft bg-white">
            <div className="container-fluid mb-4">
              <Dropdown className="dropdown-for-participants">
                <Dropdown.Toggle>
                  <div className="d-flex  justify-content-between ">
                    <span>{'Status'}</span>
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: '10px' }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 0C12.72 0 12.47 0.11 12.29 0.29L7 5.59L1.71 0.29C1.53 0.11 1.28 0 1 0C0.45 0 0 0.45 0 1C0 1.28 0.11 1.53 0.29 1.71L6.29 7.71C6.47 7.89 6.72 8 7 8C7.28 8 7.53 7.89 7.71 7.71L13.71 1.71C13.89 1.53 14 1.28 14 1C14 0.45 13.55 0 13 0Z"
                        fill="#999999"
                      />
                    </svg>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {Statuses.map((status) => (
                    <Dropdown.Item key={status} onClick={() => { handleStatusChange(status) }} style={{ backgroundColor: statusSelected.includes(status.toLowerCase()) ? '#EAF0FB' : '' }}>
                      <div className="d-flex  justify-content-between ">
                        <div>
                          <Form.Check
                            type={'checkbox'}
                            id={'default-checkbox'}
                            className="customized-checkbox"
                            checked={statusSelected.includes(status.toLowerCase())}
                            label={status}
                          />
                        </div>

                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="dropdown-for-participants">
                <Dropdown.Toggle>
                  <div className="d-flex  justify-content-between ">
                    <span>{'Country'}</span>
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: '10px' }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 0C12.72 0 12.47 0.11 12.29 0.29L7 5.59L1.71 0.29C1.53 0.11 1.28 0 1 0C0.45 0 0 0.45 0 1C0 1.28 0.11 1.53 0.29 1.71L6.29 7.71C6.47 7.89 6.72 8 7 8C7.28 8 7.53 7.89 7.71 7.71L13.71 1.71C13.89 1.53 14 1.28 14 1C14 0.45 13.55 0 13 0Z"
                        fill="#999999"
                      />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {countriesQuery.isSuccess && ['All', ...[...new Set(storeAllData.map(item => item.country))].sort((a, b) =>

                  typeof a === 'string' &&
                          (getLabelFromArray(
                            countriesQuery.data,
                            a
                          )).localeCompare(typeof b === 'string' &&
                          (getLabelFromArray(
                            countriesQuery.data,
                            b
                          )), undefined, {
                            sensitivity: 'base'
                          })
                )].map((country) => (
                  <Dropdown.Item key={country} onClick={() => { handleCountryChange(country) }} style={{ backgroundColor: countrySelected.includes(country) ? '#EAF0FB' : '' }}>
                  <div className="d-flex  justify-content-between ">
                    <div>
                      <Form.Check
                        type={'checkbox'}
                        id={'default-checkbox'}
                        className="customized-checkbox"
                        checked={countrySelected.includes(country)}
                        label={ countriesQuery.isSuccess &&
                          typeof country === 'string' &&
                          (getLabelFromArray(
                            countriesQuery.data,
                            country
                          ) || '[Country not set]')}
                      />
                    </div>

                  </div>
                </Dropdown.Item>
                ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="dropdown-for-participants">
                <Dropdown.Toggle>
                  <div className="d-flex  justify-content-between ">
                    <span>{'Group'}</span>
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: '10px' }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 0C12.72 0 12.47 0.11 12.29 0.29L7 5.59L1.71 0.29C1.53 0.11 1.28 0 1 0C0.45 0 0 0.45 0 1C0 1.28 0.11 1.53 0.29 1.71L6.29 7.71C6.47 7.89 6.72 8 7 8C7.28 8 7.53 7.89 7.71 7.71L13.71 1.71C13.89 1.53 14 1.28 14 1C14 0.45 13.55 0 13 0Z"
                        fill="#999999"
                      />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {groupsQuery.isSuccess &&
                ['All', ...[...new Set(groupsQuery.data.map(item => item.name))].sort((a, b) =>
                  a.localeCompare(b, undefined, {
                    sensitivity: 'base'
                  })
                )].map((group) => (
                  <Dropdown.Item key={group} onClick={() => { handleGroupsChange(group) }} style={{ backgroundColor: groupsSelected.includes(group) ? '#EAF0FB' : '' }}>
                  <div className="d-flex  justify-content-between ">
                    <div>
                      <Form.Check
                        type={'checkbox'}
                        id={'default-checkbox'}
                        className="customized-checkbox"
                        checked={groupsSelected.includes(group)}
                        label={group}
                      />
                    </div>

                  </div>
                </Dropdown.Item>
                ))
              }
                </Dropdown.Menu>
              </Dropdown>

              {/* ANCHOR Table */}
              {companiesQuery.isLoading && (
                <div className="d-flex justify-content-center p-5">
                  <Spinner />
                </div>
              )}
              {companiesQuery.isError && (
                <Alert variant="danger" className="mb-0 mt-3 text-center">
                  {'' + companiesQuery.error.message}
                </Alert>
              )}
              {companiesQuery.isSuccess && (
                <MUIDataTable
                  columns={[
                    {
                      label: 'Status',
                      name: 'status',
                      options: {
                        customBodyRender: (status, meta) => {
                          return (
                            <Badge
                              pill
                              variant={`outline-${
                                status === 'active'
                                  ? 'success'
                                  : status === 'pending' || status === 'applied'
                                  ? 'warning'
                                  : status === 'suspended' ||
                                    status === 'declined'
                                  ? 'danger'
                                  : 'dark'
                              }`}
                              className="text-capitalize"
                            >
                              {status}
                            </Badge>
                          )
                        }
                      }
                    },
                    { label: 'Company Name', name: 'companyName' },
                    { label: 'Nickname', name: 'nickName' },
                    {
                      label: 'Country',
                      name: 'country',
                      options: {
                        customBodyRender: (value) =>
                          countriesQuery.isSuccess &&
                          typeof value === 'string' &&
                          getLabelFromArray(
                            countriesQuery.data,
                            value.toLowerCase()
                          )
                      }
                    },
                    {
                      label: 'Groups',
                      name: 'groups',
                      options: {
                        customBodyRender: (groups, meta) => {
                          const tags = groups
                          let tagsWidth = 0
                          const tagsInColumn = []
                          const tagsInNextLine = []

                          for (const tag of tags) {
                            const tagWidth = getTagWidth(tag) // Function to get the width of a tag

                            if (tagsWidth + tagWidth <= 6000) {
                              tagsWidth += tagWidth
                              tagsInColumn.push(tag)
                            } else {
                              tagsInNextLine.push(tag)
                            }
                          }
                          return (
                            <div>
                              {tagsInColumn.map((group, index) => (
                                <Badge
                                  key={index}
                                  pill
                                  variant={`light custimized-badge mr-1
                                `}
                                  className="text-capitalize"
                                >
                                  <span
                                    style={{
                                      color: '#366BD8',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {group}
                                  </span>
                                </Badge>
                              ))}
                              {tagsInNextLine.length > 0 && (
                                <HtmlTooltip
                                  arrow
                                  title={
                                    <React.Fragment>
                                      {tagsInNextLine.map((group, index) => (
                                        <div
                                          key={index}
                                          className="font-group-weight-500"
                                        >
                                          {group}
                                        </div>
                                      ))}
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <Badge
                                    pill
                                    variant={`light
                                `}
                                    className="text-capitalize"
                                  >
                                    <span
                                      style={{
                                        color: '#366BD8',
                                        fontSize: '14px'
                                      }}
                                    >
                                      {' '}
                                      {`+${tagsInNextLine.length}`}
                                    </span>
                                  </Badge>
                                </HtmlTooltip>
                              )}
                            </div>
                          )
                        }
                      }
                    },
                    {
                      label: ' ',
                      name: 'x',
                      options: {
                        customBodyRender: (x, tableMeta) => {
                          return (
                            <div className="d-flex justify-content-end">
                              <Button
                                theme="outline-primary"
                                size="sm"
                                disabled={!(tableMeta.rowData[0] !== 'invited')}
                                onClick={() => {
                                  goPage(tableMeta.rowData[6])
                                }}
                              >
                                View
                              </Button>
                            </div>
                          )
                        }
                      }
                    },
                    {
                      lable: ' ',
                      name: 'id',
                      options: {
                        display: false
                      }
                    }
                  ]}
                  data={filteredData}
                  options={{
                    pagination: true,
                    jumpToPage: false,
                    print: false,
                    search: true,
                    download: true,
                    sort: true,
                    filter: true,
                    viewColumns: true,
                    selectableRows: _isVendor ? 'multiple' : 'none',
                    elevation: 0,
                    rowsPerPage: 100,
                    rowsSelected: selectedRowIndexes,
                    sortOrder: {
                      name: 'companyName',
                      direction: 'asc'
                    },
                    onRowSelectionChange: (
                      currentRowsSelected,
                      allRowsSelected,
                      rowsSelected
                    ) => {
                      if (
                        selectedRowIndexes.join(',') !== rowsSelected.join(',')
                      ) {
                        setSelectedRowIndexes(rowsSelected)
                      }
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                      // defer loading selected SKUs for performance reasons...
                      const getSelectedParticipants = () =>
                        selectedRows.data.map(
                          (d) => displayData[d.index].data[6]
                        )
                      return (
                        <div className="custom-toolbar-select flex-mobile-column">
                          {_isVendor && (
                            <Button
                              variant="outline-primary"
                              className="btn-sm mr-2"
                              onClick={() => {
                                setOpenDeleteParticipantModal(true)
                                setSelectedParticipants(
                                  getSelectedParticipants()
                                )
                                deselectAll()
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      )
                    }
                  }}
                />
              )}
            </div>
          </div>

          {/* { process.env.NODE_ENV !== 'production' && <pre>countriesQuery={JSON.stringify(countriesQuery, null, 2)}</pre>} */}
          {process.env.NODE_ENV !== 'production' && (
            <pre>companiesQuery={JSON.stringify(companiesQuery, null, 2)}</pre>
          )}
        </Layout.CardGrey>
      </Layout>

      {/* ANCHOR Modals */}
      <ParticipantsInviteCompanyModal
        openModal={showInviteCompanyModal}
        setOpenModal={setShowInviteCompanyModal}
      />
      <VendorDeleteParticipantModal
        openModal={openDeleteParticipantModal}
        setOpenModal={setOpenDeleteParticipantModal}
        participants={selectedParticipants}
      />
    </>
  )
}

export default Page
