
const mapArrayToObject = (array) => {
  return array.reduce((prev, curr) => {
    prev[curr.value] = curr
    return prev
  }, {})
}

/* LISTINGS */

export const OPTS_UPLOADTYPE = mapArrayToObject([
  { label: 'Itemised', value: 'itemised' },
  { label: 'Grouped', value: 'grouped' }
])
export const OPTS_SALETYPE = mapArrayToObject([
  { label: 'Auction', value: 'auction_listing' },
  { label: 'Tender', value: 'tender_listing' }
])
export const OPTS_SALETYPESELECTION = mapArrayToObject([
  { label: 'Auction', value: 'auction' },
  { label: 'Tender', value: 'tender' }
])
export const OPTS_TIMEEXTENSION = mapArrayToObject([
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' }
])
export const OPTS_AWARDLEVEL = mapArrayToObject([
  { label: 'Lot', value: 'award_lot' },
  { label: 'SKU', value: 'award_sku' }
])
export const OPTS_BIDLEVEL = mapArrayToObject([
  { label: 'Lot', value: 'bid_lot' },
  { label: 'SKU', value: 'bid_sku' }
])
export const OPTS_SHOWASKING = mapArrayToObject([
  { label: 'Yes', value: 'show_asking_bid_yes' },
  { label: 'No', value: 'show_asking_bid_no' }
])
export const OPTS_BIDSMANDATORY = mapArrayToObject([
  { label: 'Yes', value: 'bids_mandatory_yes' },
  { label: 'No', value: 'bids_mandatory_no' }
])

/**
 * Returns the label for the given value.
 * @param {*} arrayOptions
 * @param {*} value
 * @returns
 */
export const getOptionFromArray = (arrayOptions, value) => {
  if (!Array.isArray(arrayOptions)) return undefined
  const found = arrayOptions.find(lv => lv.value === value)
  if (typeof found === 'undefined') {
    return value
  }
  return found
}

/**
 * Returns the label for the given value.
 * @param {*} arrayOptions
 * @param {*} value
 * @returns
 */
export const getLabelFromArray = (arrayOptions, value) => {
  if (!Array.isArray(arrayOptions)) return undefined
  const found = arrayOptions.find(lv => lv.value === value)
  if (typeof found === 'undefined') {
    return value
  }
  return found.label
}

/**
 * Returns the label for the given value.
 * @param {*} arrayOptions
 * @param {*} value
 * @returns
 */
export const getLabelFromObject = (objectOptions, value) => {
  const found = objectOptions[value]
  if (typeof found === 'undefined') {
    return value
  }
  return found.label
}

// add .values() function to the object...
export const addValuesFunction = (optsObject) => {
  // ensure we don't include the 'values' funciton in the values!
  optsObject.values = () => Object.values(optsObject).filter(v => typeof v !== 'function')
}

// add .values() function...
const addValuesFn = [
  OPTS_UPLOADTYPE,
  OPTS_SALETYPE,
  OPTS_SALETYPESELECTION,
  OPTS_TIMEEXTENSION,
  OPTS_AWARDLEVEL,
  OPTS_BIDLEVEL,
  OPTS_SHOWASKING,
  OPTS_BIDSMANDATORY
]
addValuesFn.forEach(addValuesFunction)
